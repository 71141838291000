<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <transition name="fade" mode="out-in">
                  <b-img :src="appLogoImage" alt="logo" v-if="!isVerticalMenuCollapsed" key="pinned" />
                  <b-img :src="appLogoImageCollapsed" alt="logo" v-else class="icon" key="unpinned" />
                </transition>
              </span>
              <!-- <h2 class="brand-text text-white">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      ref="ps"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
          shallShadowTop = hasScrollingSpace
        }
      "
    >
      <vertical-nav-menu-items :items="Menu" class="navigation navigation-main" v-if="Menu != null" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
    <div :class="{ 'd-block': shallShadowTop }" class="shadow-top" />
    <div class="sidebar-footer expanded">
      <ul>
        <b-nav-item-dropdown toggle-class="d-flex align-items-center dropdown-user-link m-0 p-1" class="dropdown-user" no-caret>
          <template #button-content>
            <!-- <b-avatar size="30" variant="light-primary" :src="require('@/assets/images/avatars/13-small.png')" class="badge-minimal" badge-variant="success" /> -->
            <b-avatar size="30" alt="" class="badge-minimal">
              <FeatherIcon icon="UserIcon" />
            </b-avatar>
            <div class="d-sm-flex d-none flex-column ml-1 text-white" v-if="!isVerticalMenuCollapsed">
              <p class="small mb-0" style="line-height:11px">
                {{ `${$acl.user().firstName} ${$acl.user().lastName}` }}
              </p>
              <!-- <span class="small">{{ $acl.user().roles.join(',') }}</span> -->
            </div>
          </template>

          <!-- <b-dropdown-item link-class="d-flex align-items-center p-50">
            <feather-icon size="14" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-dropdown-item> -->

          <b-dropdown-item link-class="d-flex align-items-center p-50" @click="logOut()">
            <feather-icon size="14" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { LogOutIcon } from 'vue-feather-icons'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    FeatherIcon,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['Menu']),
  },
  methods: {
    ...mapActions(['LogOut']),
  },
  setup(props, { root }) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    const shallShadowTop = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'))

    function logOut() {
      root.$store.dispatch('LogOut')
      root.$router.push('/login')
    }
    const ps = ref({})

    const hasScrollingSpace = computed(() => {
      var element = ps.value.$el
      if (element) {
        const overflow = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
        const scrolledToBottom = element.scrollTop === element.scrollHeight - element.offsetHeight

        return overflow && !scrolledToBottom
      }
      return false
    })
    // App Name
    const { appName, appLogoImage, appLogoImageCollapsed } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,
      shallShadowTop,
      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageCollapsed,
      ps,
      hasScrollingSpace,
      logOut,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.sidebar-footer {
  height: 250px;
  // background-color: #fff;
  z-index: 2000;
  width: 100%;

  ul {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}
.main-menu .main-menu-content {
  height: calc(100% - 8.9rem) !important;
}

.shadow-top {
  background: linear-gradient(180deg, #040f07 44%, rgba(4, 15, 7, 0.51) 73%, rgba(4, 15, 7, 0));
  display: none;
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 100%;
  pointer-events: none;
  filter: blur(5px);
  margin-top: -1.5rem;
}
</style>
