<template>
  <ul>
    <component :is="resolveNavItemComponent(item)" v-for="item in manuItems" :key="item.header || item.title" :item="item" />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    manuItems() {
      const menu = []
      for (let i = 0, len = this.items.length; i < len; i += 1) {
        if (this.$acl.canOneOf(this.items[i].permissions)) {
          menu.push(this.items[i])
        }
      }

      return menu
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>

